import {Component, Input, OnInit} from '@angular/core';
import {Paragraphs} from '../../models/paragraph.model';
import SampleJsonToilettageParagraph from '../../assets/jsonfiles/toilettage/paragraphs.json';
import SampleJsonConseilAMonMaitreParagraph from '../../assets/jsonfiles/conseils_a_mon_maitre/paragraphs.json';
import SampleJsonLaPensionParagraph from '../../assets/jsonfiles/la_pension/paragraphs.json';
import SampleJsonPromenadesParagraph from '../../assets/jsonfiles/promenades/paragraphs.json';
import SampleJsonVisitesParagraph from '../../assets/jsonfiles/visites/paragraphs.json';
import SampleJsonBetisierParagraph from '../../assets/jsonfiles/betisier/paragraphs.json';
import {NavigationEnd, Router} from '@angular/router';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit {

  paragraphs: Paragraphs;

  currentPageDisplayed: string;

  constructor(private router: Router) {
    router.events.subscribe(event => {

      if (event instanceof NavigationEnd ) {
        this.currentPageDisplayed = event.url;
      }
    });
  }

  ngOnInit() {
    switch (this.currentPageDisplayed) {
      case '/':
        break;
      case '/toilettage':
        this.paragraphs = SampleJsonToilettageParagraph;
        break;
      case '/conseils_a_mon_maitre':
        this.paragraphs = SampleJsonConseilAMonMaitreParagraph;
        break;
      case '/la_pension':
        this.paragraphs = SampleJsonLaPensionParagraph;
        break;
      case '/promenades':
        this.paragraphs = SampleJsonPromenadesParagraph;
        break;
      case '/visites':
        this.paragraphs = SampleJsonVisitesParagraph;
        break;
      case '/betisier':
        this.paragraphs = SampleJsonBetisierParagraph;
        break;
    }
  }

}
