import {Component, Input, OnInit} from '@angular/core';
import {Paragraph} from '../../../models/paragraph.model';
import {Image} from '../../../models/image.model';

@Component({
  selector: 'app-imagebox',
  templateUrl: './imagebox.component.html',
  styleUrls: ['./imagebox.component.scss']
})
export class ImageboxComponent implements OnInit {

  @Input() image: Image;

  constructor() { }

  ngOnInit() {
  }

}
