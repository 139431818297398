import { Component, OnInit } from '@angular/core';
import {Paragraphs} from '../../../models/paragraph.model';
import {Images} from '../../../models/image.model';
import {NavigationEnd, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import SampleJsonAccueilParagraph from '../../../assets/jsonfiles/accueil/paragraphs.json';
import SampleJsonToilettageParagraph from '../../../assets/jsonfiles/toilettage/paragraphs.json';
import SampleJsonToilettageImage from '../../../assets/jsonfiles/toilettage/images.json';
import SampleJsonConseilAMonMaitreParagraph from '../../../assets/jsonfiles/conseils_a_mon_maitre/paragraphs.json';
import SampleJsonConseilAMonMaitreImage from '../../../assets/jsonfiles/conseils_a_mon_maitre/images.json';
import SampleJsonLaPensionParagraph from '../../../assets/jsonfiles/la_pension/paragraphs.json';
import SampleJsonLaPensionImage from '../../../assets/jsonfiles/la_pension/images.json';
import SampleJsonPromenadesParagraph from '../../../assets/jsonfiles/promenades/paragraphs.json';
import SampleJsonPromenadesImage from '../../../assets/jsonfiles/promenades/images.json';
import SampleJsonVisitesParagraph from '../../../assets/jsonfiles/visites/paragraphs.json';
import SampleJsonVisitesImage from '../../../assets/jsonfiles/visites/images.json';
import SampleJsonBetisierParagraph from '../../../assets/jsonfiles/betisier/paragraphs.json';
import SampleJsonBetisierImage from '../../../assets/jsonfiles/betisier/images.json';
import SampleJsonAccueilImage from '../../../assets/jsonfiles/accueil/images.json';

@Component({
  selector: 'app-accueil-page-content',
  templateUrl: './accueil-page-content.component.html',
  styleUrls: ['./accueil-page-content.component.scss']
})
export class AccueilPageContentComponent implements OnInit {

  paragraphList: Paragraphs;
  imageList: Images;

  currentPageDisplayed: string;

  constructor(private router: Router,
              private titleService: Title ) {

    this.titleService.setTitle('Chiens du Monde - Conseils à mon maître');

    router.events.subscribe(event => {

      if (event instanceof NavigationEnd ) {
        this.currentPageDisplayed = event.url;
      }
    });
  }

  ngOnInit() {
    // go to the top of the page
    window.scrollTo(0, 0);

    switch (this.currentPageDisplayed) {
      case '/accueil':
        this.paragraphList = SampleJsonAccueilParagraph;
        this.imageList = SampleJsonAccueilImage;
        break;
      case '/':
        this.paragraphList = SampleJsonAccueilParagraph;
        this.imageList = SampleJsonAccueilImage;
        break;
      case '/toilettage':
        this.paragraphList = SampleJsonToilettageParagraph;
        this.imageList = SampleJsonToilettageImage;
        break;
      case '/conseils_a_mon_maitre':
        this.paragraphList = SampleJsonConseilAMonMaitreParagraph;
        this.imageList = SampleJsonConseilAMonMaitreImage;
        break;
      case '/la_pension':
        this.paragraphList = SampleJsonLaPensionParagraph;
        this.imageList = SampleJsonLaPensionImage;
        break;
      case '/promenades':
        this.paragraphList = SampleJsonPromenadesParagraph;
        this.imageList = SampleJsonPromenadesImage;
        break;
      case '/visites':
        this.paragraphList = SampleJsonVisitesParagraph;
        this.imageList = SampleJsonVisitesImage;
        break;
      case '/betisier':
        this.paragraphList = SampleJsonBetisierParagraph;
        this.imageList = SampleJsonBetisierImage;
        break;
    }


  }
}
