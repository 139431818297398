import {AfterViewInit, Component, OnInit} from '@angular/core';
import * as L from 'leaflet';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements AfterViewInit {

  map;

  constructor() { }

  ngAfterViewInit(): void {
    this.createMap();
  }

  createMap() {
    const chiensdumonde = {
      lat: 43.620662,
      lng: 6.970477,
    };

    this.map = L.map('map', {
      center: [chiensdumonde.lat, chiensdumonde.lng],
      zoom: 17,
    });

    const mainLayer = L.tileLayer('https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png', {
      minZoom: 12,
      maxZoom: 19,
      attribution: 'données © <a href="//osm.org/copyright">OpenStreetMap</a>/ODbL - rendu <a href="//openstreetmap.fr">OSM France</a>',
    }).addTo(this.map);

    const iconMarker = L.icon({
      iconUrl: './../../../assets/images/marker.png',

      iconSize:     [50, 50], // size of the icon
    });

    const marker = L.marker([chiensdumonde.lat, chiensdumonde.lng], {icon: iconMarker}).addTo(this.map);

    marker.bindPopup('<center><strong>Chiens du Monde </strong><br/> ' +
      '41 avenue de Grasse 06370 Mouans-Sartoux <br/>Tél: 04.92.28.18.01 </center>');
  }

}
