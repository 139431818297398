import {BrowserModule, Title} from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { AppRoutingModule } from './app-routing.module';
import { TextboxComponent } from './components/textbox/textbox.component';
import { ContentComponent } from './content/content.component';
import { ImageboxComponent } from './components/imagebox/imagebox.component';
import { ToilettagePageContentComponent } from './content/toilettage-page-content/toilettage-page-content.component';
import { ConseilsAMonMaitrePageContentComponent } from './content/conseils-a-mon-maitre-page-content/conseils-a-mon-maitre-page-content.component';
import { LaPensionPageContentComponent } from './content/la-pension-page-content/la-pension-page-content.component';
import { PromenadesPageContentComponent } from './content/promenades-page-content/promenades-page-content.component';
import { VisitesPageContentComponent } from './content/visites-page-content/visites-page-content.component';
import { BetisierPageContentComponent } from './content/betisier-page-content/betisier-page-content.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { AccueilPageContentComponent } from './content/accueil-page-content/accueil-page-content.component';
import { MapComponent } from './components/map/map.component';
import { AccueilButtonComponent } from './components/accueil-button/accueil-button.component';

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    TextboxComponent,
    ContentComponent,
    ImageboxComponent,
    ToilettagePageContentComponent,
    ConseilsAMonMaitrePageContentComponent,
    LaPensionPageContentComponent,
    PromenadesPageContentComponent,
    VisitesPageContentComponent,
    BetisierPageContentComponent,
    NotFoundComponent,
    AccueilPageContentComponent,
    MapComponent,
    AccueilButtonComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [
    Title
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
