import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {ContentComponent} from './content/content.component';
import {LaPensionPageContentComponent} from './content/la-pension-page-content/la-pension-page-content.component';
import {ToilettagePageContentComponent} from './content/toilettage-page-content/toilettage-page-content.component';
import {ConseilsAMonMaitrePageContentComponent} from './content/conseils-a-mon-maitre-page-content/conseils-a-mon-maitre-page-content.component';
import {PromenadesPageContentComponent} from './content/promenades-page-content/promenades-page-content.component';
import {VisitesPageContentComponent} from './content/visites-page-content/visites-page-content.component';
import {BetisierPageContentComponent} from './content/betisier-page-content/betisier-page-content.component';
import {NotFoundComponent} from './not-found/not-found.component';
import {AccueilPageContentComponent} from './content/accueil-page-content/accueil-page-content.component';

const routes: Routes = [
  // {path: 'themes/:id', canActivate: [AuthGuardService], component: QuizListComponent},
  {path: 'toilettage', component: ToilettagePageContentComponent},
  {path: 'conseils_a_mon_maitre', component: ConseilsAMonMaitrePageContentComponent},
  {path: 'la_pension', component: LaPensionPageContentComponent},
  {path: 'promenades', component: PromenadesPageContentComponent},
  {path: 'visites', component: VisitesPageContentComponent},
  /*{path: 'betisier', component: BetisierPageContentComponent},*/
  {path: 'accueil', component: AccueilPageContentComponent},
  { path: '',   redirectTo: '/accueil', pathMatch: 'full' }, // redirect to `first-component`
  {path: '**', component: NotFoundComponent},
  /*{path: '**', redirectTo: '/', pathMatch: 'full'}, // path ** means every page*/
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
