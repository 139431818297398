import {Component, Input, OnInit} from '@angular/core';
import {Image} from '../../../models/image.model';

@Component({
  selector: 'app-accueil-button',
  templateUrl: './accueil-button.component.html',
  styleUrls: ['./accueil-button.component.scss']
})
export class AccueilButtonComponent implements OnInit {

  @Input() image: Image;

  constructor() { }

  ngOnInit() {
  }

}
