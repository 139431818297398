import {Component, Input, OnInit} from '@angular/core';
import {Paragraph} from '../../../models/paragraph.model';

@Component({
  selector: 'app-textbox',
  templateUrl: './textbox.component.html',
  styleUrls: ['./textbox.component.scss']
})
export class TextboxComponent implements OnInit {

  @Input() paragraph: Paragraph;

  constructor() { }

  ngOnInit() {
  }

}
