import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  title = 'chien-du-monde';

  currentPageDisplayed: string;

  constructor(private router: Router) {
    router.events.subscribe(event => {

      if (event instanceof NavigationEnd ) {
        this.currentPageDisplayed = event.url;
        console.log(this.currentPageDisplayed); // event.url has current url
        // your code will goes here
      }
    });
  }

  ngOnInit() {
    console.log(this.currentPageDisplayed); // event.url has current url
  }
}
